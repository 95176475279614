import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: './home/home.module#HomeModule'
  },
  {
    path: 'terms',
    pathMatch: 'full',
    loadChildren: './terms/terms.module#TermsModule'
  },
  {
    path: 'user/verify',
    pathMatch: 'full',
    loadChildren: './user/verify-email/verify-email.module#VerifyEmailModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
