import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NavBarService } from './nav-bar.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  public isMenuOpened = false;
  public addBg: Boolean = true;
  public withShadow: Boolean = false;
  @Input() public isLight = false;

  constructor(
    private navBarService: NavBarService,
    private translate: TranslateService
  ) {
    navBarService.changeEmitted$
      .pipe(distinctUntilChanged())
      .subscribe(
        val => {
          this.addBg = val;
        });

    navBarService.themeChangeEmitted$
      .pipe(distinctUntilChanged())
      .subscribe(
        val => {
          this.isLight = val === 'light';
        });

    navBarService.withShadowChangeEmitted$
      .pipe(distinctUntilChanged())
      .subscribe(
        val => {
          this.withShadow = val;
        });
  }

  toggleMenu() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  closeMenu() {
    this.isMenuOpened = false;
  }
}
