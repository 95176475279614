import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NavBarService {

  private emitChangeSource = new Subject<Boolean>();
  changeEmitted$ = this.emitChangeSource.asObservable();

  private emitThemeChangeSource = new Subject<String>();
  themeChangeEmitted$ = this.emitThemeChangeSource.asObservable();

  private emitWithShadowChangeSource = new Subject<Boolean>();
  withShadowChangeEmitted$ = this.emitWithShadowChangeSource.asObservable();

  emitChange(change: Boolean) {
    this.emitChangeSource.next(change);
  }

  emitThemeChange(change: String) {
    this.emitThemeChangeSource.next(change);
  }

  emitWithShadowChange(withShadow: Boolean) {
    this.emitWithShadowChangeSource.next(withShadow);
  }
}
