import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() classList: String;
  constructor() { }

  get finalClassList(): String {
    return `btn ${this.classList}`;
  }

  ngOnInit() {
  }

}
