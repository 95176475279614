import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { LangChangerService } from './lang-changer.service';

@Component({
  selector: 'app-lang-changer',
  templateUrl: './lang-changer.component.html',
  styleUrls: ['./lang-changer.component.scss']
})
export class LangChangerComponent implements OnInit, AfterViewInit {

  @Input() isLight = false;
  @Input() position: string;
  public right = false;
  public bottom = false;
  public currentLangCode: String = 'th';
  public currentLang: String = 'ภาษาไทย';

  constructor(
    public langChangerService: LangChangerService
  ) { }

  ngOnInit() {
    this.currentLangCode = this.langChangerService.currentLangCode;
    this.currentLang = this.langChangerService.currentLang;
    this.langChangerService.currentLangCode$.subscribe(val => {
      this.currentLangCode = val;
    });
    this.langChangerService.currentLang$.subscribe(val => {
      this.currentLang = val;
    });

    this.switchLanguage('th');

    if (this.position === 'right') {
      this.right = true;
    } else if (this.position === 'bottom') {
      this.bottom = true;
    } else {
      this.right = true;
    }
  }

  ngAfterViewInit() {

  }

  switchLanguage(language: string) {
    this.langChangerService.switchLanguage(language);
  }
}
