import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParallaxScrollModule } from 'ng2-parallaxscroll';

import { ButtonComponent } from './button/button.component';
import { LangChangerComponent } from './lang-changer/lang-changer.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ButtonComponent, LangChangerComponent],
  exports: [
    ButtonComponent,
    LangChangerComponent,
    CommonModule,
    TranslateModule,
    ParallaxScrollModule
  ]
})
export class SharedModule { }
