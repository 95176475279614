import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

// AoT requires an exported function for factories
// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    { provide: 'WINDOW', useFactory: getGlobalWindow },
    { provide: 'VIEWPORT_WIDTH', useFactory: getViewportWidth },
    { provide: 'VIEWPORT_HEIGHT', useFactory: getViewportHeight }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getGlobalWindow() {
  return (typeof window !== undefined) ? window : null;
}

export function getViewportHeight() {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

export function getViewportWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}
