import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangChangerService {

  public currentLangCode: String = 'th';
  public currentLang: String = 'ภาษาไทย';
  public currentLang$: Subject<String> = new Subject<String>();
  public currentLangCode$: Subject<String> = new Subject<String>();

  constructor(
    private translate: TranslateService
  ) { }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.setLanguage(language);
  }

  setLanguage(language: string) {
    if (language === 'en') {
      this.currentLang = 'English';
      this.currentLangCode = 'en';
    } else {
      this.currentLang = 'ภาษาไทย';
      this.currentLangCode = 'th';
    }
    this.currentLang$.next(this.currentLang);
    this.currentLangCode$.next(this.currentLangCode);
  }
}
