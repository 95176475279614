import { Component, OnInit } from '@angular/core';
import { LangChangerService } from '../../shared/lang-changer/lang-changer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public currentLangCode: String = 'th';

  constructor(
    private langChangerService: LangChangerService
  ) { }

  ngOnInit() {
    this.currentLangCode = this.langChangerService.currentLangCode;
    this.langChangerService.currentLangCode$.subscribe(val => {
      this.currentLangCode = val;
    });
  }
}
